import {map, catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import {default as swal} from 'sweetalert2';
import { AppConstants } from '../constants';

@Injectable()
export class AuthService{

    private locationWatcher = new EventEmitter();
    hasLoggedIn = new EventEmitter<boolean>();
    hasName = new EventEmitter<string>();
    constructor
    (private http: HttpClient){}

    informLogin(){
        this.hasLoggedIn.emit(true);
    }

    informName(name){
        this.hasName.emit(name);
    }

    signup(user){
        const body = JSON.stringify(user);
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.http.post(AppConstants.API_ENDPOINT + 'user', body, {headers: headers})
            .pipe(map((response: any) => {
                return response; })
            , catchError((error: any) => {
                    swal(error.title, error.error.message, 'error');
                    return throwError(error);
            }));
    }

    signin(user){
        const body = JSON.stringify(user);
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(AppConstants.API_ENDPOINT + 'user/signin', body, {headers: headers})
            .pipe(map((response: any) => {
                this.informLogin();
                return response;
            })
            , catchError((error: HttpErrorResponse) => {
                    return throwError(error);
            }));
    }

    check(user){
        const body = JSON.stringify(user);
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.http.post(AppConstants.API_ENDPOINT + 'user/check', body, {headers: headers})
            .pipe(map((response: any) => {
                return response;
            })
            , catchError((error: HttpErrorResponse) => {
                    return throwError(error);
            }));
    }

    logout(){
        localStorage.removeItem('token');
    }

    isLoggedIn(){
        return localStorage.getItem('token') !== null;
    }

    getUserName(){
        return localStorage.getItem('name');
    }

    getUserId(){
        return localStorage.getItem('userId');
    }

    resquestRecovery(email : string){
        const body = JSON.stringify({email: email});
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.http.post(AppConstants.API_ENDPOINT + 'user/recovery', body, {headers: headers})
            .pipe(map((response: any) => {

                return response;
            })
            , catchError((error: any) => {

                swal(error.title, error.error.message, 'error');
                return throwError(error);
            }));
    }

    resetPassword(token : string, password: string){
        const body = JSON.stringify({token: token, password: password});
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.http.post(AppConstants.API_ENDPOINT + 'user/reset', body, {headers: headers})
            .pipe(map((response: any) => {

                return response;
            })
            , catchError((error: any) => {
                swal(error.title, error.error.message, 'error');
                return throwError(error);
            }));
    }

}