import { throwError } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from './constants';
import { map, catchError } from 'rxjs/operators';
@Injectable()
export class PreferenciasService {
    constructor(private http: HttpClient) { }
    hasChanged = new EventEmitter<boolean>();
    informChanges() {
        this.hasChanged.emit(true);
    }
    find() {
        const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
        return this.http.get(AppConstants.API_ENDPOINT + 'prefs' + token)
            .pipe(map((response: any) => {
                return response.obj;
            }), catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }));
    }
    save(cadastro) {
        const body = JSON.stringify(cadastro);
        const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.patch(AppConstants.API_ENDPOINT + 'prefs/' + token, body, { headers: headers })
            .pipe(map((response: any) => {
                this.informChanges();
                return response;
            }), catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }));
    }
    setEmailAniversario(cadastro) {
        const body = JSON.stringify(cadastro);
        const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.patch(AppConstants.API_ENDPOINT + 'prefs/email-aniversario' + token, body, { headers: headers })
            .pipe(map((response: any) => {
                this.informChanges();
                return response;
            }), catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }));
    }

    getUpdatedLogo() {
        const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
        return this.http.get(AppConstants.API_ENDPOINT + 'prefs/logo/' + token)
            .pipe(map((response: any) => {
                const value = response.value;
                this.informChanges();
                return value;
            }), catchError((error: HttpErrorResponse) => {
                //this.alertModalService.handleAlert(error);
                return throwError(error);
            }));
    }

}
