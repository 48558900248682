import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class LoggedInGuard implements CanActivate {
  private jwtHelper = new JwtHelperService();
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    if (this.authService.isLoggedIn()){
        const token = localStorage.getItem('token');
        if (!(this.jwtHelper.isTokenExpired(token))) { return true; }
    }

    localStorage.removeItem('token');
    this.router.navigate(['/auth']);
    return false;
  }

}