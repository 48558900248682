import { Routes, RouterModule} from '@angular/router';
import { MainComponent } from './main.component';
import { AuthenticationComponent } from './auth/authentication.component';
import { LoggedInGuard } from './auth/loggedin-guard.service';
import { AmbienteSelectComponent } from './ambiente-select/ambiente-select.component';


const APP_ROUTES: Routes = [
    {path: '', redirectTo: '/ambientes', pathMatch: 'full'},
    {path: 'painel/:id', component: MainComponent, canActivate: [LoggedInGuard]},
    {path: 'ambientes', component: AmbienteSelectComponent, canActivate: [LoggedInGuard]},
    {path: 'auth', component: AuthenticationComponent, loadChildren: './auth/auth.module#AuthModule', data: { title: 'Bem-vindo' }},
];

export const routing = RouterModule.forRoot(APP_ROUTES);
