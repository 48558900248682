import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { routing} from './app.routing';
import { MainComponent } from './main.component';
import { MainService } from './main.service';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SafeHtmlPipe } from './safeHtmlPipe';

import { TextMaskModule } from 'angular2-text-mask';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoggedInGuard } from './auth/loggedin-guard.service';
import { AuthService } from './auth/auth.service';
import { AuthenticationComponent } from './auth/authentication.component';
import { AmbienteSelectComponent } from './ambiente-select/ambiente-select.component';
import { PreferenciasService } from './PreferenciasService';
import { AmbientesService } from './ambiente-select/AmbientesService';




@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SafeHtmlPipe,
    AuthenticationComponent,
    AmbienteSelectComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    routing,
    FontAwesomeModule,
    TextMaskModule,
    FormsModule,
    RecaptchaModule
  ],
  providers: [MainService, LoggedInGuard, AuthService, PreferenciasService, AmbientesService],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
