import { Component, OnInit, SecurityContext } from '@angular/core';
import { MainService } from './main.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConstants } from './constants';
import { faPhoneAlt as faPhone, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, Title} from '@angular/platform-browser';
import { PreferenciasService } from './PreferenciasService';
import { AmbientesService } from './ambiente-select/AmbientesService';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {

  constructor(private service: MainService, private route: ActivatedRoute, private sanitizer: DomSanitizer, private titleService : Title,
    private preferenciasService : PreferenciasService,
    private ambientesService : AmbientesService
    ) {}

  horaAtual = '';

  nomeConsultorio = '';
  texto = '';
  logo = '';
  ambientes = [];
  pacienteNome = '';
  salaNome = '';
  ambiente = '';
  ambienteId = 0;
  chamadaId = 0;
  audio = new Audio();
  showNome = true;
  historico = [];
  voz = false;
  voice;
  

  ngOnInit() {

    this.route.params.forEach((params: Params) => {
      const id: number = +params['id'];
      this.ambienteId = id;
      if (id === 0){
        this.ambiente = 'Geral';
      }else{
        this.ambientesService.find(id).subscribe(data => {
          this.ambiente = data[0].descricao;
        });
      }
    });

    this.nomeConsultorio = localStorage.getItem('consultorio');
    this.preferenciasService.find().subscribe(data => {
      const company = data[0];
      localStorage.setItem('consultorio', company.name);
      this.nomeConsultorio = company.name;
      this.voz = company.painelVoz ? company.painelVoz : false;
      console.log("voz ativa:" + this.voz)
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (!isSafari) {
        let self = this;
        speechSynthesis.addEventListener(
          "voiceschanged",
          () => {
            self.doVoices(self);
          })
      } else {
        let self = this;
          self.doVoices(self);
      }

    });

    this.ambientesService.findAll(1, 2000, '').subscribe(data => {
        this.ambientes = data[0];
        this.ambientes.unshift({_id: 0, descricao: 'Geral'});
    });

    this.audio.src = AppConstants.API_ENDPOINT + 'sounds/call.mp3';
    this.audio.load();

    this.getChamadas();




    setInterval(() => {
      const data = new Date();
      const hora = data.getHours() < 10 ? '0' + data.getHours() : data.getHours();
      const min = data.getMinutes() < 10 ? '0' + data.getMinutes() : data.getMinutes();
      this.horaAtual = hora + ':' + min;
    }, 1000);


  }

  doVoices(self){
    const voices = window.speechSynthesis.getVoices();
    const voice = voices.find((voice) => /pt-BR/.test(voice.lang))
    console.log(voice);
    self.voice = voice;
  }
  

  delay = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async pisca() {
    this.showNome = false;
    for (let i = 0; i < 4; i++){
      this.showNome = !this.showNome;
      await this.delay(300);
    }
    this.showNome = true;
  }

  getChamadas(){
    this.service.getChamada(this.ambienteId).subscribe(result => {
      if (result.length > 0){
        if (this.chamadaId !== result[0]._id){
          if (this.pacienteNome !== ''){
            this.historico.unshift({nome: this.pacienteNome, sala: this.salaNome, hora: this.horaAtual});
          }


          this.pacienteNome = result[0].paciente.nome;
          this.salaNome = result[0].sala.descricao;

          this.audio.play();
          this.pisca();

          

          window.setTimeout(() => {

            console.log(this.voz);
            console.log(this.voice);

            if(this.voz && this.voice){
              console.log("Entrou aqui");
              const utterance = new SpeechSynthesisUtterance()
              utterance.text = result[0].paciente.nome + ". Favor dirigir-se a "  + result[0].sala.descricao // word é a palavra que desejamos pronunciar
              utterance.lang = 'pt-BR' // língua a pronunciar
              utterance.voice = this.voice // voz defina acima
              window.speechSynthesis.speak(utterance);
            }
          }, 3000)
          

          this.chamadaId = result[0]._id;

        }
      }
      setTimeout(() => {
        this.getChamadas();
      }, 100);
    }, error => {
      setTimeout(() => {
        this.getChamadas();
      }, 1000);
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }



}
