import { Component, OnInit } from '@angular/core';
import { AppConstants } from './constants';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  constructor(
    private authService: AuthService,
        private http: HttpClient,
  ){}

  ngOnInit(){
    this.updateToken();

    setInterval(() => {
        this.updateToken();
    }, 60000);
  }

  updateToken(){
    if (!localStorage.getItem('token')) {return; }
    const token = localStorage.getItem('token') ? '?token=' + localStorage.getItem('token') : '';
    this.http.get(AppConstants.API_ENDPOINT + 'user/refresh' + token)
    .subscribe((response: any) => {
        const data = response;
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('name', data.name);
    }, error => {});

}





}
