import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { PreferenciasService } from '../PreferenciasService';
import { AmbientesService } from './AmbientesService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ambiente',
  templateUrl: './ambiente-select.component.html'
})
export class AmbienteSelectComponent implements OnInit {

name = 'Iniciando...';
ambientes = [];
  constructor(
        private http: HttpClient,
        private preferenciasService : PreferenciasService,
        private ambientesService : AmbientesService,
        private router: Router
  ){}

  ngOnInit(){

    this.preferenciasService.find().subscribe(data => {
        const company = data[0];
        localStorage.setItem('consultorio', company.name);
        this.name = company.name;
    });

    this.ambientesService.findAll(1, 2000, '').subscribe(data => {
        this.ambientes = data[0];
        this.ambientes.unshift({_id: 0, descricao: 'Geral'});
    });

  }

  selAmbiente(i){
      this.router.navigateByUrl('/painel/' + i._id);
  }

  logout(){
    localStorage.removeItem('token');
    this.router.navigateByUrl('/auth/signin');
  }

}
