
import {throwError} from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from './constants';
import {map, catchError} from 'rxjs/operators';

@Injectable()

export class MainService {
    constructor(private http: HttpClient ) {}

    carregouVozes = new EventEmitter<any>();

    informarCarregamentoVozes(){
        this.carregouVozes.emit(true)
    }

    getChamada(ambiente){
        const token = localStorage.getItem('token') ? '&token=' + localStorage.getItem('token') : '';
        return this.http.get(AppConstants.API_ENDPOINT + 'painel?ambiente=' + ambiente + token)
        .pipe(map((response: any) => {
            const result = response.obj;
            return result;
        })
        , catchError((error: HttpErrorResponse) => {
                return throwError(error);
        }));
    }

}
