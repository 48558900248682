import {map, debounceTime, catchError, switchMap, distinctUntilChanged } from 'rxjs/operators';
import {throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConstants } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class AmbientesService {

    private cadastros : any[];
    constructor(private http: HttpClient){}

    findAll(page, limit, search){
        const token = localStorage.getItem('token') ? '&token=' + localStorage.getItem('token') : '';
        return this.http.get(AppConstants.API_ENDPOINT + 'ambiente/get?page=' + page + '&limit=' + limit + '&search=' + search + token)
        .pipe(map((response: any) => {
            const cadastros = response.obj;
            const results = [];
            results.push(response.obj);
            results.push(response.count);
            return results;
        })
        , catchError((error: HttpErrorResponse) => throwError(error)));
    }

    search(terms: Observable<string>, page, limit) {
    return terms.pipe(debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.findAll(page, limit, term);
      }));
    }

    find(id){
        const token = localStorage.getItem('token') ? '&token=' + localStorage.getItem('token') : '';
        return this.http.get(AppConstants.API_ENDPOINT + 'ambiente/getById?id=' + id + token)
        .pipe(map((response: any) => {
            return response.obj;

        })
        , catchError((error: HttpErrorResponse) => {
                return throwError(error);
        }));
    }



    findSalas(ambiente){
        const token = localStorage.getItem('token') ? '&token=' + localStorage.getItem('token') : '';
        return this.http.get(AppConstants.API_ENDPOINT + 'ambiente/getSalas?ambiente=' + ambiente + token)
        .pipe(map((response: any) => {
            const cadastros = response.obj;
            this.cadastros = cadastros;
            const results = [];
            results.push(cadastros);
            results.push(response.count);
            return results;
        })
        , catchError((error: HttpErrorResponse) => {
                return throwError(error);
        }));
    }



}